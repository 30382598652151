import React, {useContext, useState} from 'react';
import Modal from 'react-modal';
import { FormattedMessage } from 'react-intl'
import LocalizedLink from '../../LocalizedLink/LocalizedLink';
import LocaleContext from '../../../contexts/LocaleContext';
import './styles.scss'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#___gatsby');

function ModalMessage({modalIsOpen, setIsOpen, page}) {
  
  const localeCode = useContext(LocaleContext);
  function closeModal() {
    setIsOpen(false);
  }

  const [ btnDownloadState, setBtnDownloadState ] = useState(localeCode === 'es' ? 'Descargar' : 'Download');
  const [ btnState, setBtnState ] = useState(localeCode === 'es' ? 'Enviar' : 'Send');



  return (
    <div >
      <input type="submit" className="submit-buttton" value={ page === "una-introduccion-a-la-adquirencia" ? btnDownloadState : btnState } />
      <Modal
        className='modal'
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <h2 className='modal-title'><FormattedMessage id="form.modal.title" /></h2>
        <button className='close-modal-btn' onClick={closeModal}>x</button>
        <div>
          {
            page === "una-introduccion-a-la-adquirencia" ?
              <p className='modal-desc'><FormattedMessage id="form.modal.blog.description" /></p>
              :
              <p className='modal-desc'><FormattedMessage id="form.modal.blog.description_mdp" /></p>
          }
        </div>
        <div className='modal-blog-button-container'>
          {
            page === "una-introduccion-a-la-adquirencia" &&
              <button className="btn--basic" >
                <a href="https://drive.google.com/file/d/1gXBkOCFmfvumBkvmhPMqJJ3NbYZ2SOea/view?usp=sharing" download>
                  <FormattedMessage id="blog.btn_download_pdf"/>
                  <svg width="24" height="24" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-2">
                    <path d="M21.2784 15.2948V19.2948C21.2784 19.8252 21.0677 20.3339 20.6926 20.709C20.3175 21.0841 19.8088 21.2948 19.2784 21.2948H5.27838C4.74795 21.2948 4.23924 21.0841 3.86417 20.709C3.48909 20.3339 3.27838 19.8252 3.27838 19.2948V15.2948" stroke="#023365" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7.27838 10.2948L12.2784 15.2948L17.2784 10.2948" stroke="#023365" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12.2784 15.2948V3.2948" stroke="#023365" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </a>
              </button>
          }
          {   
            page === 'adquirencia-en-mexico-kushki' &&
            <button className="btn--basic" >
              <a href="https://drive.google.com/file/d/1jCOYLFUUJIski5OtJ5TWggDUZSRyzuDj/view?usp=drive_link" download>
                <FormattedMessage id="blog.btn_download_pdf"/>
                <svg width="24" height="24" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-2">
                  <path d="M21.2784 15.2948V19.2948C21.2784 19.8252 21.0677 20.3339 20.6926 20.709C20.3175 21.0841 19.8088 21.2948 19.2784 21.2948H5.27838C4.74795 21.2948 4.23924 21.0841 3.86417 20.709C3.48909 20.3339 3.27838 19.8252 3.27838 19.2948V15.2948" stroke="#023365" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M7.27838 10.2948L12.2784 15.2948L17.2784 10.2948" stroke="#023365" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12.2784 15.2948V3.2948" stroke="#023365" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </a>
            </button>
          }
          <LocalizedLink
              to="/"
              className="button-faq"
              activeClassName="active"
            >
          <FormattedMessage id="form.modal.button-home" />
          </LocalizedLink>
        </div>
      </Modal>
    </div>
  );
}

export default ModalMessage;