import addToMailchimp from 'gatsby-plugin-mailchimp';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { defaultFormatValues } from '../../../data/defaultFormat';

import officeImg from './assets/images/office.svg'

import './styles.scss';


const BlogSubscribe: React.FC = () => {

  const [email, setEmail] = useState('');

  const [submitted, setSubmitted] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const intl = useIntl();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    addToMailchimp(email)
      .then((data) => {
        if(data.result !== 'success') {
          setErrorMessage(data.msg)
        } else {
          localStorage.setItem('newsletterSubscriber', 'true');
        }
        setSubmitted(true);
      })
      .catch((error: Error) => {
        // Errors in here are client side
        // Mailchimp always returns a 200
      });
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  };

  return (
    <form onSubmit={handleSubmit} className="blog-subscribe-form">
      {
        !submitted &&
        <>
          <div className="container">
            <div className="row">
              <div className="col-md-4 d-flex flex-column justify-content-center">
                <img className="blog-subscribe-form__img" src={officeImg} alt="" />
              </div>    
              <div className="col-md-8 d-flex flex-column justify-content-center  ">
              <p className="blog-subscribe-form__message" style={{whiteSpace: 'pre-wrap'}}>
                <FormattedMessage id="blog.subscribe_message"
                  values={defaultFormatValues}
                />
              </p>
              <div className="blog-subscribe-form__wrapper">
                <input
                  placeholder={intl.formatMessage({id: 'blog.subscribe_input_placeholder'})}
                  name="email"
                  type="text"
                  onChange={handleEmailChange}
                />
                <button type="submit">
                  <FormattedMessage id="blog.subscribe_cta"/>
                </button>
              </div>
              </div>    
            </div>
          </div>
        </>
      }
      {
        submitted && errorMessage === '' &&
        <p className="lead mb-0">
          <FormattedMessage id="blog.subscribe_success"
            values={defaultFormatValues}
          />
        </p>
      }
      {
        submitted && errorMessage !== '' &&
        <p
          className="lead mb-0"
          dangerouslySetInnerHTML={{
            __html: errorMessage,
          }}
        />
      }

    </form>
  );
};

export default BlogSubscribe;
