import { graphql, useStaticQuery } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import React from 'react';
import { useIntl } from 'react-intl';
import './styles.scss';

interface BlogSocialShareProps {
  title: string;
  url: string;
}

const BlogSocialShare: React.FC<BlogSocialShareProps> = ({title, url}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            siteName
          }
        }
      }
    `,
  );

  url = `${site.siteMetadata.siteUrl}${url}`;

  const socialMediaLinks = [
    {
      icon: <svg width="44" height="36" viewBox="0 0 44 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.8371 36C30.441 36 39.5226 22.1485 39.5226 10.1366C39.5226 9.74317 39.5226 9.35152 39.4962 8.96163C41.2629 7.67486 42.788 6.0816 44 4.25646C42.3524 4.99157 40.6047 5.47365 38.815 5.68662C40.6996 4.55061 42.11 2.76386 42.7838 0.658902C41.0118 1.71771 39.0731 2.4639 37.0515 2.86528C35.6904 1.40798 33.8903 0.442993 31.9297 0.119647C29.9691 -0.203699 27.9573 0.132625 26.2056 1.07657C24.4539 2.02052 23.0601 3.51947 22.2397 5.34146C21.4193 7.16345 21.2182 9.2069 21.6674 11.1556C18.0783 10.9744 14.5672 10.0353 11.3619 8.39912C8.15669 6.76296 5.32899 4.46636 3.0624 1.65842C1.908 3.65951 1.55443 6.02841 2.07368 8.28277C2.59293 10.5371 3.94596 12.5075 5.85728 13.7926C4.42066 13.7498 3.01537 13.3595 1.76 12.6549C1.76 12.6921 1.76 12.7311 1.76 12.7701C1.76057 14.8687 2.48206 16.9026 3.8021 18.5267C5.12213 20.1508 6.95944 21.2651 9.0024 21.6807C7.67337 22.0456 6.27894 22.099 4.92624 21.8366C5.5031 23.6428 6.62616 25.2222 8.13836 26.3541C9.65057 27.4859 11.4763 28.1136 13.3602 28.1492C10.1635 30.6789 6.21466 32.0521 2.14896 32.048C1.43071 32.0466 0.713159 32.0028 0 31.9169C4.12832 34.5845 8.93185 35.9995 13.8371 35.9929" fill="#293036"/>
      </svg>,
      messageId: 'blog.share.twitter',
      name: 'Twitter',
      shareLink: `https://twitter.com/share?url=${url}&text=${title}&via=kushkioficial&hashtags=kushki`
    },
    {
      icon: <svg width="53" height="97" viewBox="0 0 53 97" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M50.089 0.02L37.624 0C23.62 0 14.57 9.285 14.57 23.656V34.563H2.037C0.953997 34.563 0.0769958 35.441 0.0769958 36.524V52.327C0.0769958 53.41 0.954997 54.287 2.037 54.287H14.57V94.163C14.57 95.246 15.447 96.123 16.53 96.123H32.882C33.965 96.123 34.842 95.245 34.842 94.163V54.287H49.496C50.579 54.287 51.456 53.41 51.456 52.327L51.462 36.524C51.462 36.004 51.255 35.506 50.888 35.138C50.521 34.77 50.021 34.563 49.501 34.563H34.842V25.317C34.842 20.873 35.901 18.617 41.69 18.617L50.087 18.614C51.169 18.614 52.046 17.736 52.046 16.654V1.98C52.046 0.899 51.17 0.022 50.089 0.02Z" fill="#293036"/>
      </svg>,
      messageId: 'blog.share.facebook',
      name: 'Facebook',
      shareLink: `https://facebook.com/sharer.php?u=${url}`
    },
    {
      icon: <svg width="157" height="149" viewBox="0 0 157 149" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.99783 149H35.6595V48.4696H1.99783V149Z" fill="#293036"/>
      <path d="M18.6057 34.7355H18.832C30.5601 34.7355 37.863 27.022 37.863 17.3605C37.6392 7.50433 30.5575 0 19.0444 0C7.5324 0 0 7.5064 0 17.3605C0 27.0199 7.31744 34.7355 18.6057 34.7355Z" fill="#293036"/>
      <path d="M123.349 149H157V91.3555C157 60.4628 140.393 46.1028 118.244 46.1028C100.374 46.1028 92.3919 55.872 87.9248 62.7204V48.4681H54.2751C54.7153 57.8966 54.2751 148.998 54.2751 148.998H87.9197V92.8459C87.9197 89.8496 88.1424 86.8491 89.0416 84.7038C91.4683 78.6971 96.9946 72.4756 106.304 72.4756C118.473 72.4756 123.349 81.6976 123.349 95.2128V149Z" fill="#293036"/>
      </svg>,
      messageId: 'blog.share.linkedin',
      name: 'Linkedin',
      shareLink: `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
    },
    {
      icon: <svg viewBox="0 0 16 13" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 0H3C1.3 0 0 1.3 0 3v7c0 1.7 1.3 3 3 3h10c1.7 0 3-1.3 3-3V3c0-1.7-1.3-3-3-3zm0 2c.2 0 .3.1.5.1L8 5.8 2.6 2.2c.1-.1.2-.2.4-.2h10zm0 9H3c-.6 0-1-.4-1-1V4.2l5.4 3.6c.2.1.4.2.6.2.2 0 .4-.1.6-.2L14 4.2V10c0 .6-.4 1-1 1z" fillRule="nonzero" fill="#293036"/>
      </svg>,
      messageId: 'blog.share.email',
      name: 'Email',
      shareLink: `mailto:?subject=${title}&body=Revisa%este%artículo%que %20encontré%en%el%blog%de%Kushki%3A%20${url}`,
    }
  ]

  const intl = useIntl();

  return <ul className="blog-social-share">
    {
      socialMediaLinks.map(socialMediaLink =>
        <li key={socialMediaLink.name}>
          <OutboundLink
            href={socialMediaLink.shareLink}
            aria-label={intl.formatMessage({id: socialMediaLink.messageId})}
            className={`blog-social-share__item blog-social-share__item--${socialMediaLink.name.toLowerCase()}`}>
            {socialMediaLink.icon}
          </OutboundLink>
        </li>
      )
    }

  </ul>;
};

export default BlogSocialShare;
