import Img, { FluidObject } from 'gatsby-image';
import React from 'react';
import LocalizedLink from '../../LocalizedLink/LocalizedLink';
import { BlogAuthor } from '../BlogPost';

interface BlogEntryProps {
  slug: string;
  image: FluidObject;
  title: string;
  excerpt: string;
  date: string;
  className: string;
  author?: BlogAuthor;
}

const BlogEntry: React.FC<BlogEntryProps> = ({
  className,
  slug,
  image,
  title,
  excerpt,
  author,
  date
}) => (
  <div className={`blog-entry ${className}`}>
    <LocalizedLink to={`/blog/${slug}`}>
      <div className="blog-preview-pic">
        <Img
          fluid={image}
          imgStyle={{objectFit: 'cover', objectPosition: 'center' }}
          style={{ maxHeight: '100%' }}
        />
      </div>
      <h4 className="mt-2">{title}</h4>
      <p
        className="lead text-muted"
        dangerouslySetInnerHTML={{
          __html: excerpt,
        }}
      />
    </LocalizedLink>
    <div className="blog-entry-footer">
        {author ?
          <div className="author author--blog-entry">
            <img src={author.profilePic.resize.src} />
            <div>
              <p className="authorName">{author.name}</p>
              <p className="authorBio">{author.bio}</p>
            </div>
          </div> : ''
        }
      <small>{date}</small>
    </div>
  </div>
);

export default BlogEntry;
