import React, { useEffect, useState } from 'react';
import './styles.scss';

interface ReadingProgressProps {
  target: any;
}

const ReadingProgress: React.FC<ReadingProgressProps> = ({ target }) => {
  const [readingProgress, setReadingProgress] = useState(0);
  const scrollListener = () => {
    if (!target.current) {
      return;
    }

    const element = target.current;
    const startPosition = element.offsetTop;
    const totalHeight = element.clientHeight;
    const windowScrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (windowScrollTop <= startPosition) {
      return setReadingProgress(0);
    }

    if (windowScrollTop > totalHeight + startPosition) {
      return setReadingProgress(100);
    }

    setReadingProgress(((windowScrollTop - startPosition) / (totalHeight - window.innerHeight)) * 100);
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollListener);
    return () => window.removeEventListener('scroll', scrollListener);
  });

  return <div className={`reading-progress-bar`} style={{width: `${readingProgress}%`}} />;
};

export default ReadingProgress;
