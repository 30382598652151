import React from 'react'
import { FormattedMessage } from 'react-intl'
import LocalizedLink from '../../LocalizedLink/LocalizedLink'

import './styles.scss'

const BlogWWU: React.FC = () => {
  return (
    <div className="blogWwU">
      <div className="container">
        <div className="row">
          <p className="blogWwU-title">
          <FormattedMessage id="blog.wwu_description"/>
          </p>
          <LocalizedLink to="/work-with-us">
            <button className="blogWwU-button btn--green">
              <FormattedMessage id="buttons.wwu"/>
            </button>
          </LocalizedLink>
        </div>
      </div>
    </div>
  )
}

export default BlogWWU