import * as React from 'react';
import IndexLayout from '../layouts';

import { graphql } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';

import patternImg from '../components/Blog/BlogHero/pattern.svg';
import BlogLatest from '../components/Blog/BlogLatest/BlogLatest';
import { BlogPost } from '../components/Blog/BlogPost';
import BlogSocialShare from '../components/Blog/BlogSocialShare/BlogSocialShare';
import BlogSubscribe from '../components/Blog/BlogSubscribe/BlogSubscribe';
import Footer from '../components/Footer/Footer';
import ReadingProgress from '../components/ReadingProgress/ReadingProgress';
import SEO from '../components/SEO/SEO';
import { LocaleTypes } from '../data/locales';
import { Calendar, Clock } from '@kushki/suka-react-system-icons';
import { FormattedMessage } from 'react-intl';
import BlogCta from '../components/Blog/BlogCTA/BlogCta';
import BlogWWU from '../components/Blog/BlogWWU/BlogWWU';
import Form from '../components/Blog/BlogForm/Form';


interface BlogTemplateProps {
  data: {
    site: {
      siteMetadata: {
        blogTitle: string;
      };
    };
    contentfulBlogPost: {
      title: string;
      slug: string;
      form: boolean;
      buttonCta: boolean;
      body: {
        childMarkdownRemark: {
          html: string;
          excerpt: string;
          timeToRead: number;
        }
      }
      date: string;
      isoDate: string;
      mainImage: {
        resize: {
          src: string;
        };
        sizes: FluidObject;
        fluid: FluidObject;
      }
      author?: AuthorProps
    };
    nextBlogPosts: {
      nodes: BlogPost[]
    }
  };
  pathContext: {
    slug: string;
    locale: LocaleTypes;
  };
  location: Location;
}

interface AuthorProps {
  name: string;
  bio: string;
  profilePic: {
    resize: {
      src: string;
    };
  };
  className?: string;
}

const Author: React.SFC<AuthorProps> = ({name, bio, profilePic, className}) => (
  <div className={`${className} author`}>
    <img src={profilePic.resize.src} />
    <div>
      <p className="authorName">{name}</p>
      <p className="authorBio">{bio}</p>
    </div>
  </div>
)

const BlogTemplate: React.SFC<BlogTemplateProps> = ({ data, pathContext, location }) => {
  const content = ( ): React.ReactNode => {
    const { contentfulBlogPost } = data;
    const html = contentfulBlogPost.body.childMarkdownRemark.html
    const author = contentfulBlogPost.author;
    const target = React.createRef<HTMLElement>();

    const blogForm = [
      'adquirencia-en-mexico-simplificando-los-pagos-electronicos',
      'retos-y-oportunidades-de-pagos-digitales-en-latinoamerica',
      'una-introduccion-a-la-adquirencia',
      'temporadas-de-ofertas-en',
      'certificacion-pci-pagos-mas-seguros-para-tus-clientes-y-tu-negocio',
      'latinoamerica-digitalizada-un-recorrido-por-5-paises'
    ]

    const newsletterSubscriber = typeof window !== 'undefined' && window.localStorage.getItem('newsletterSubscriber');

    return (
      <>
        <SEO
          title={`${contentfulBlogPost.title} | ${data.site.siteMetadata.blogTitle}`}
          description={contentfulBlogPost.body.childMarkdownRemark.excerpt}
          image={contentfulBlogPost.mainImage.resize.src}
          directImage={true}
          noTitleTemplate={true}
          articleAuthor={author ? author.name : undefined}
          articleDate={contentfulBlogPost.isoDate}
          timeToRead={contentfulBlogPost.body.childMarkdownRemark.timeToRead}
        />
        <ReadingProgress target={target} />
        <section className="blog-title bg-primary text-white" style={{ backgroundImage: `url(${patternImg})` }}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-8 offset-lg-2">
                <h1 className="mb-layout-2">{contentfulBlogPost.title}</h1>
                  {author ?
                    <Author name={author.name} bio={author.bio} profilePic={author.profilePic} className="mb-2" /> :
                    ''
                  }
                  <p className="mb-2 d-flex align-items-center">
                    <Calendar height={16} className="mr-2" /> {contentfulBlogPost.date}
                  </p>

                  <p className="mb-0 d-flex align-items-center">
                    <Clock height={16} className="mr-2" /> <FormattedMessage id="blog.minutes_to_read" values={{
                    minutes: contentfulBlogPost.body.childMarkdownRemark.timeToRead
                  }}/>
                  </p>
              </div>
            </div>
          </div>
        </section>
        <section className="pt-5 mb-5" ref={target}>
          <div className="container">
            <div className="row">
              <div className="col">
                <Img
                  fluid={contentfulBlogPost.mainImage.fluid}
                  className="my-4"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-2">
                  <BlogSocialShare title={contentfulBlogPost.title} url={location.pathname} />
              </div>
              <div className="col-12 col-lg-8">
                <div
                  className="markdown-content pb-7"
                  dangerouslySetInnerHTML={{ __html: html }}
                />
                {
                  // contentfulBlogPost.slug === "una-introduccion-a-la-adquirencia" || contentfulBlogPost.slug === 'temporadas-de-ofertas-en' || contentfulBlogPost.slug === 'certificacion-pci-pagos-mas-seguros-para-tus-clientes-y-tu-negocio' 
                  contentfulBlogPost.form ?
                  <Form slug={contentfulBlogPost.slug} />
                  : <></>
                }
                {
                  !newsletterSubscriber &&
                  <BlogSubscribe />
                }
                {
                  contentfulBlogPost.buttonCta &&
                    <section className="row">
                      <div className="col-md-6">
                        {
                          <BlogCta />
                        }
                      </div>
                      <div className="col-md-6">
                        { <BlogWWU /> }
                      </div>
                    </section>
                } 
              </div>
            </div>
          </div>
        </section>

        <BlogLatest posts={data.nextBlogPosts.nodes}/>
        <Footer theme="primary" />
      </>
    )
  }

  return (
    <IndexLayout
      render={ content }
      navbarTheme="light"
      locale={ pathContext.locale }
      location={location}
    />
  );
};

export default BlogTemplate;

export const query = graphql`
  query($slug: String!, $locale: String!) {
    site {
      siteMetadata {
        blogTitle
      }
    }
    contentfulBlogPost(slug: {eq: $slug}, node_locale: {eq: $locale}) {
      title
      buttonCta
      slug
      form
      date(formatString: "MMMM DD, YYYY", locale: $locale)
      isoDate: date
      body {
        childMarkdownRemark {
          html
          excerpt
          timeToRead
        }
      }
      mainImage {
        resize(width: 900, quality: 90) {
          src
        }
        sizes(maxWidth: 1280) {
          src
        }
        fluid(maxWidth: 1280) {
          ...GatsbyContentfulFluid
        }
      }
      author {
        name
        bio
        profilePic {
          resize(width: 60, height: 60, quality: 75) {
            src
          }
        }
      }
    }
    nextBlogPosts: allContentfulBlogPost(
      sort: {order: DESC, fields: date}
      filter: {
        node_locale: {eq: $locale},
        slug: {ne: $slug}
      },
      limit: 4
    ) {
      nodes {
        title
        slug
        body {
          childMarkdownRemark {
            excerpt
          }
        }
        date(formatString: "MMMM DD, YYYY", locale: $locale)
        author {
          bio
          name
          profilePic {
            resize(width: 60, height: 60, quality: 75) {
              src
            }
          }
        }
        mainImage {
          fluid(maxWidth: 800) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;
