import React, { FC } from 'react'
import BlogEntry from '../BlogEntry/BlogEntry';
import { BlogPost } from '../BlogPost'

interface BlogLatestProps {
  posts: BlogPost[]
}

const BlogLatest: FC <BlogLatestProps> = ({posts}) => {
  return <section className="py-layout-5 bg-light-gray-3">
    <div className="container">
      <h4 className="text-dark-gray-2">Más de nuestro blog</h4>
      <div className="row">
        {posts.map(post => (
          <div className="col-md-6 col-lg-3" key={post.title}>
            <BlogEntry
              className="mt-4"
              slug={post.slug}
              image={post.mainImage.fluid}
              title={post.title}
              excerpt={post.body.childMarkdownRemark.excerpt}
              date={post.date}
              author={post.author}
            />
          </div>
          ))
        }
      </div>
    </div>
  </section>
}

export default BlogLatest
