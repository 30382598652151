import React from 'react'
import { FormattedMessage } from 'react-intl'
import SalesButton from '../../SalesButton/SalesButton'

import './styles.scss'

const BlogCta:React.FC = () => {
  return (
    <div className="blogCta">
      <div className="container">
        <div className="row">
          <p className="blogCta-title">
            <FormattedMessage id="blog.cta_description"/>  
          </p>
          <SalesButton positionTag="top">
            <FormattedMessage id="buttons.sales"/>
          </SalesButton>
        </div>
      </div>
    </div>
  )
}

export default BlogCta